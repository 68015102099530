// import Head from "next/head";
// import Image from "next/image";
// import { Inter } from "next/font/google";
import Home from "@/components/Home";
import SEOHead from "@/components/SEO";

export default function Index() {
  return (
    <>
      <SEOHead
        title={"Sparkle Infotech - Your Top Rated Plus Outsourcing Solution"}
        description={
          "Sparkle infotech is a leading web development company specializing in custom web applications and providing end-to-end services to its client globally. Our creative and highly experienced teams of web development consultants are providing web development services to achieve your business goals."
        }
        url={process.env.NEXT_PUBLIC_SITE_URL}
        twitterHandler={"discussionfora1"}
        imageUrl={`${process.env.NEXT_PUBLIC_SITE_URL}/uploads/sparkleinfotechseo_80a1ccf6b9.png`}
      />
      <Home />
    </>
  );
}
