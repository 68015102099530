import {
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";

export default function Banner({
  data,
  style,
  classNameparent,
  classNamechild,
  homeBanner,
  blogCareerBanner,
}) {
  const router = useRouter();
  const { slug } = router.query;

  const { banner_image, Subtitle, Title, Description, btn_url, btn_text } =
    data;

  const isNot = !(btn_url && btn_text);

  return (
    <Flex
      w={"full"}
      backgroundImage={`url(${
        process.env.NEXT_PUBLIC_SERVER_URL + banner_image?.data?.attributes.url
      })`}
      h={
        homeBanner
          ? { base: "55vh", md: "50vh" }
          : classNamechild
          ? "100%"
          : "32vh"
      }
      backgroundRepeat="no-repeat"
      backgroundSize={"cover"}
      backgroundPosition={
        blogCareerBanner || classNamechild ? "bottom center" : "top center"
      }
      backgroundColor={
        blogCareerBanner || homeBanner
          ? "rgba(35, 24, 52, 0.29)"
          : "transparent"
      }
      backgroundBlendMode={
        blogCareerBanner
          ? { base: "color", md: "unset" }
          : homeBanner
          ? "color"
          : "unset"
      }
      marginBottom={0}
      className={classNameparent}
    >
      <VStack
        color={style?.color}
        w={"full"}
        justify={"center"}
        alignItems={style?.align || "center"}
        px={useBreakpointValue({ base: 5, md: 8, lg: 1 })}
        bgGradient={
          isNot && classNameparent === "contactOverlay"
            ? "linear(to-r, blackAlpha.600, transparent)"
            : ""
        }
        mb={classNamechild ? 56 : 0}
        mt={classNamechild ? 16 : 0}
      >
        <Stack
          maxW={homeBanner ? "full" : "700px"}
          w={homeBanner ? { base: "full", md: "full", lg: "50%" } : "full"}
          alignItems={homeBanner ? "left" : "center"}
          textAlign={style?.textAlign || "center"}
          spacing={6}
        >
          {Subtitle && classNameparent !== "testimonialsOverlay" && (
            <Text
              fontWeight={"normal"}
              fontSize={"12"}
              letterSpacing={4}
              lineHeight={1.2}
              className="subtitle"
              color={classNamechild ? "blue.10" : "white"}
            >
              {Subtitle}
            </Text>
          )}
          <Heading
            as={classNamechild ? "h2" : "h1"}
            color={
              classNamechild
                ? "blue.10"
                : useColorModeValue(homeBanner ? "white" : "#e9ebef")
            }
            textAlign={
              router.pathname !== "/"
                ? useColorModeValue("center", classNamechild)
                : useColorModeValue(homeBanner ? "left" : "center")
            }
            pos="relative"
            className={
              router.pathname !== "/" || classNamechild
                ? "all-banner-title"
                : "banner-title"
            }
            fontSize={
              classNamechild
                ? { base: "3xl", md: "32px" }
                : { base: "3xl", md: "4xl" }
            }
            mt={classNamechild ? "4 !important" : 0}
          >
            {Title}
          </Heading>
          {Description && (
            <Text
              fontWeight={"sm"}
              lineHeight={1.5}
              whiteSpace={"pre-line"}
              mt={
                router.pathname !== "/" || classNamechild ? "4 !important" : 3
              }
              pt={homeBanner ? 5 : 0}
              pr={homeBanner ? { base: 0, md: 5 } : 0}
              color={
                router.pathname !== "/"
                  ? useColorModeValue(
                      classNamechild ? "lightBlue.10" : "#e9ebef"
                    )
                  : useColorModeValue(homeBanner ? "white" : "gray.10")
              }
              textAlign={
                router.pathname !== "/"
                  ? useColorModeValue("center", classNamechild)
                  : useColorModeValue(homeBanner ? "left" : "center")
              }
              fontSize={
                homeBanner
                  ? { base: "20px", md: "xl", lg: "2xl" }
                  : { base: "lg", md: "xl" }
              }
            >
              {Description}
            </Text>
          )}
          {btn_url && btn_text && (
            <Stack direction={"row"}>
              <NextLink href={btn_url}>
                <Button
                  textDecoration={"none !important"}
                  bg={"orange.10"}
                  color={"white"}
                  size="lg"
                  width="200px"
                  height="50px"
                  _hover={{ bg: "blue.10" }}
                >
                  {btn_text}
                </Button>
              </NextLink>
            </Stack>
          )}
        </Stack>
      </VStack>
    </Flex>
  );
}
